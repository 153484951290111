import React from 'react';

export default function Squiggle1() {
  return (
    <svg
      width="436"
      height="159"
      viewBox="0 0 436 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="squiggle1"
    >
      <path
        d="M259.153 13.0611C182.786 2.44244 -4.4955 22.7946 3.2321 86.9486C10.9597 151.103 133.238 163.049 241.424 152.873C349.611 142.696 428.706 97.1251 432.796 58.1899C436.887 19.2547 378.703 2 314.61 2C250.516 2 185.058 8.63664 105.964 29.8739"
        stroke="#F5C3B4"
        strokeWidth="4"
        strokeLinecap="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
