import React from 'react';
import { Link } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import Arrow from '../../images/theme2/ico-arrow.svg';

const serializers = {
  types: {
    block: ({ children }) => <h2 className="split-lines">{children}</h2>,
  },
  marks: {
    strong: ({ children }) => <span>{children}</span>,
  },
};

export default function WhatIDo({ data }) {
  return (
    <section className="what-i-do">
      <div className="wrapper">
        <div className="floating-tag blue ball-reveal">
          <div className="tag-content">
            <p>What I offer</p>
          </div>
        </div>

        <div className="what-i-offer">
          <div className="text-content">
            <PortableText blocks={data._rawTitle} serializers={serializers} />
            <p className="para split-lines">{data.para}</p>
            <Link to="/about" className="button element-reveal">
              About me
              <span className="arrow">
                <img src={Arrow} alt="Arrow" />
              </span>
            </Link>
          </div>

          <div className="all-services stagger-reveal">
            {data.services.map((service, i) => (
              <div className="service" key={i}>
                <div className="accordion heading">
                  <span className="s-no">{`0${i + 1}/`}</span>
                  <h3 className="title">{service.title}</h3>
                  <div className="plus-icon">
                    <span />
                    <span />
                  </div>
                </div>
                <div className="accordion-content">
                  <p className="info para small">{service.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
