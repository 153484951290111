import React, { useEffect } from 'react';
import { Gradient } from '../../uitilities/Gradient';
import Mail from '../../images/theme3/ico-mail-outline.svg';
import Dribbble from '../../images/theme3/ico-dribbble-outline.svg';
import Instagram from '../../images/theme3/ico-instagram-outline.svg';
import Linkedin from '../../images/theme3/ico-linkedin-outline.svg';

export default function Hero1() {
  useEffect(() => {
    const gradient = new Gradient();
    gradient.initGradient('#gradient-canvas');
  }, []);

  return (
    <div className="hero t3-hero">
      <div className="wrapper">
        <div className="hero-content">
          <div className="left">
            <div className="heading">
              <h1 className="show-up">
                <div className="line-wrap">Rekhchand</div>
                <div className="line-wrap">Sahu</div>
              </h1>
              <p className="subhead split-n-wrap">
                UX/UI designer, Creative developer
              </p>
            </div>

            <div className="social-links">
              <a href="mailto:rekhchandsahu07@gmail.com" className="gmail">
                <img src={Mail} alt="Gmail" />
              </a>
              <a
                href="https://dribbble.com/r3khchand"
                target="_blank"
                className="dribbble"
                rel="noreferrer"
              >
                <img src={Dribbble} alt="Dribbble" />
              </a>
              <a
                href="https://www.instagram.com/rekhchand_design/"
                target="_blank"
                className="instagram"
                rel="noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
              <a
                href="https://www.linkedin.com/in/rekhchandsahu/"
                target="_blank"
                className="linkedin"
                rel="noreferrer"
              >
                <img src={Linkedin} alt="Linkedin" />
              </a>
            </div>
          </div>

          <div className="right">
            <canvas id="gradient-canvas" data-transition-in />
          </div>
        </div>
      </div>
    </div>
  );
}
