import React from 'react';
import { Link } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import TagCarousel from './TagCarousel';

const serializers = {
  types: {
    block: ({ children }) => <h2 className="element-reveal">{children}</h2>,
  },
  marks: {
    strong: ({ children }) => <span>{children}</span>,
  },
};

export default function WhatIOffer({ data }) {
  return (
    <section id="what-i-offer">
      <div className="wrapper">
        <div className="sec-heading">
          <PortableText blocks={data._rawTitle} serializers={serializers} />
          <TagCarousel title="What I Offer" />
        </div>

        <div className="all-services">
          {data.services.map((service, i) => (
            <div className="service accordion-reveal-1" key={i}>
              <div className="accordion heading">
                <div className="s-no">
                  <span>{`0${i + 1}.`}</span>
                </div>
                <h3 className="title">{service.title}</h3>
                <div className="plus-icon">
                  <span />
                  <span />
                </div>
              </div>
              <div className="accordion-content">
                <p className="info para">{service.para}</p>
              </div>
              <div className="stroke" />
            </div>
          ))}
        </div>

        <div className="about-blurb">
          <p className=" split-lines">{data.para}</p>
          <Link to="/about" className="button element-reveal">
            More about me
          </Link>
        </div>
      </div>
    </section>
  );
}
