import React from 'react';
import Hero2 from './Hero2';
import SelectedWork from './SelectedWork';
import WhatIDo from './WhatIDo';
import MoreWork from './MoreWork';

import '../../css/theme2/theme-style.scss';

export default function HomeContent2({ data }) {
  const { whatIDo } = data.sanityHomepage;
  const { allWork } = data.sanityHomepage.moreWork;

  return (
    <>
      <Hero2 />
      <SelectedWork />
      <WhatIDo data={whatIDo} />
      <MoreWork allWork={allWork} />
    </>
  );
}
