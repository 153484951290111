import React, { useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import TagCarousel from './TagCarousel';
import { scramlingTextHandler } from '../../uitilities/caseHovers';

export default function SelectWorks() {
  useEffect(() => {
    scramlingTextHandler();
  });

  const data = useStaticQuery(graphql`
    {
      allSanityCaseStudy(sort: { fields: order }) {
        nodes {
          title
          slug {
            current
          }
          field
          thumbnail {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const allCases = data.allSanityCaseStudy.nodes;

  return (
    <section id="select-work" className="t3-select-work">
      <div className="wrapper wrapper-2">
        <div className="work-tag">
          <div className="tag-empty" />
          <div className="tag-wrap">
            <TagCarousel title="Selected work" />
          </div>
        </div>

        <div className="all-cases">
          {allCases.map((work, i) => (
            <div className="case-contain element-reveal" key={i}>
              <Link
                to={`/${work.slug.current}`}
                className="scramble-contain case-study"
              >
                <div className="thumb item-img-wrap">
                  <div className="thumb-content item-img">
                    <GatsbyImage
                      image={work.thumbnail.asset.gatsbyImageData}
                      alt={work.title}
                    />
                  </div>
                </div>
                <h3 className="text-scramble">{work.title}</h3>
                <p className="info para small">{work.field}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
