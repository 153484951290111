import React from 'react';

export default function Squiggle2() {
  return (
    <svg
      width="374"
      height="7"
      viewBox="0 0 374 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="squiggle2"
    >
      <path
        d="M2 4.99996C38.0976 3.02671 137.366 3.02684 181.761 3.02671C237.048 3.02655 323.631 0.716691 353.835 3.02671M368.973 4.44925L372 5"
        stroke="#F5C3B4"
        strokeWidth="4"
        strokeLinecap="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
