import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import TagCarousel from './TagCarousel';
import Dribbble from '../../images/theme3/ico-dribbble-outline.svg';
import Instagram from '../../images/theme3/ico-instagram-outline.svg';

export default function MoreWork({ allWork }) {
  return (
    <section className="more-work">
      <div className="wrapper wrapper-2">
        <div className="more-work-contain">
          <div className="tag-wrap">
            <div className="tag-content">
              <TagCarousel title="More work" />
            </div>
          </div>

          <div className="work-grid">
            {allWork.map((work, i) => (
              <div className={`work-contain work${i + 1}`} key={i}>
                <div className="work">
                  {work.asset && (
                    <GatsbyImage
                      image={work.asset.gatsbyImageData}
                      alt={work.alt}
                    />
                  )}
                  {work.video && (
                    <video
                      src={work.video.asset.url}
                      poster={work.thumb.asset.url}
                      type="video/mp4"
                      muted
                      autoPlay
                      loop
                      playsInline
                      disablePictureInPicture
                      controlsList="nodownload noplaybackrate"
                    />
                  )}
                </div>
              </div>
            ))}
            <a
              href="https://dribbble.com/r3khchand"
              target="_blank"
              className="social dribbble"
              rel="noreferrer"
            >
              <img src={Dribbble} alt="dribbble" />
            </a>
            <a
              href="https://www.instagram.com/rekhchand_design/"
              target="_blank"
              className="social instagram"
              rel="noreferrer"
            >
              <img src={Instagram} alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
