import React from 'react';
import Mail from '../../images/theme2/ico-gmail.svg';
import Dribbble from '../../images/theme2/ico-dribbble.svg';
import Instagram from '../../images/theme2/ico-instagram.svg';
import Linkedin from '../../images/theme2/ico-linkedIn.svg';
import MailBadge from '../../images/theme2/badge-email.svg';
import DribbbleBadge from '../../images/theme2/badge-dribbble.svg';
import InstagramBadge from '../../images/theme2/badge-instagram.svg';
import LinkedinBadge from '../../images/theme2/badge-linkedin.svg';
import Squiggle1 from '../../icons/Squiggle1';
import Squiggle2 from '../../icons/Squiggle2';

export default function Hero2() {
  return (
    <div className="hero t2-hero hero-squiggle">
      <div className="wrapper">
        <div className="hero-content">
          <h1>
            <div className="inner">UX/UI designer,</div>
            <div className="inner">
              <span>
                Creative
                <Squiggle1 />
              </span>{' '}
              developer
            </div>
          </h1>

          <p className="specialised">Specialised in</p>
          <h2>
            <span>
              Websites & Webapps
              <Squiggle2 />
            </span>
          </h2>

          <div className="social-links">
            <a href="mailto:rekhchandsahu07@gmail.com" className="gmail">
              <img src={Mail} alt="Gmail" />
              <img src={MailBadge} alt="Gmail" className="badge" />
            </a>
            <a
              href="https://dribbble.com/r3khchand"
              target="_blank"
              className="dribbble"
              rel="noreferrer"
            >
              <img src={Dribbble} alt="Dribbble" />
              <img src={DribbbleBadge} alt="Dribbble" className="badge" />
            </a>
            <a
              href="https://www.instagram.com/rekhchand_design/"
              target="_blank"
              className="instagram"
              rel="noreferrer"
            >
              <img src={Instagram} alt="Instagram" />
              <img src={InstagramBadge} alt="Instagram" className="badge" />
            </a>
            <a
              href="https://www.linkedin.com/in/rekhchandsahu/"
              target="_blank"
              className="linkedin"
              rel="noreferrer"
            >
              <img src={Linkedin} alt="Linkedin" />
              <img src={LinkedinBadge} alt="Linkedin" className="badge" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
