import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PageHead from '../components/PageHead';
import Layout from '../components/Layout';
import HomeContent1 from '../components/theme1/HomeContent1';
import HomeContent2 from '../components/theme2/HomeContent2';
import HomeContent3 from '../components/theme3/HomeContent3';

export function Head({ location, data }) {
  const { seo } = data.sanityHomepage;

  return (
    <PageHead
      title={seo.title && seo.title}
      description={seo.description && seo.description}
      image={seo.ogImage && seo.ogImage.asset.url}
      location={location}
    />
  );
}

export default function IndexPage({ location, data }) {
  const [activeTheme, setActiveTheme] = useState(() => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem('themeRekh');
    }
    return null;
  });

  const [dummyState, setDummyState] = useState(false); // to trigger loco refresh when theme is changed

  return (
    <Layout
      location={location}
      theme={activeTheme}
      setTheme={setActiveTheme}
      dummyState={dummyState}
      setDummyState={setDummyState}
    >
      {activeTheme === 'theme1' && <HomeContent1 data={data} />}
      {activeTheme === 'theme2' && <HomeContent2 data={data} />}
      {activeTheme === 'theme3' && <HomeContent3 data={data} />}
    </Layout>
  );
}

export const query = graphql`
  {
    sanityHomepage {
      whatIDo {
        _rawTitle
        para
        services {
          title
          para
        }
      }
      moreWork {
        allWork {
          ... on SanityFigure {
            alt
            asset {
              gatsbyImageData
            }
          }
          ... on SanityVideoFile {
            video {
              asset {
                url
              }
            }
            thumb {
              asset {
                url
              }
            }
            alt
          }
        }
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
