import React from 'react';
import Hero1 from './Hero1';
import SelectedWork from './SelectedWork';
import WhatIDo from './WhatIDo';
import MoreWork from './MoreWork';

import '../../css/theme1/theme-style.scss';

export default function HomeContent1({ data }) {
  const { whatIDo } = data.sanityHomepage;
  const { allWork } = data.sanityHomepage.moreWork;

  return (
    <>
      <Hero1 />
      <SelectedWork />
      <WhatIDo data={whatIDo} />
      <MoreWork allWork={allWork} />
    </>
  );
}
