import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function SelectedWork() {
  const data = useStaticQuery(graphql`
    {
      allSanityCaseStudy(sort: { fields: order }) {
        nodes {
          title
          slug {
            current
          }
          field
          thumbnail {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const allCases = data.allSanityCaseStudy.nodes;

  return (
    <section className="selected-work">
      <div className="all-cases">
        {allCases.map((work, i) => (
          <Link
            to={`/${work.slug.current}`}
            className="case-study element-reveal"
            key={i}
          >
            <div className="thumb item-img-wrap">
              <div className="thumb-content item-img">
                <GatsbyImage
                  image={work.thumbnail.asset.gatsbyImageData}
                  alt={work.title}
                />
              </div>
            </div>
            <h3>{work.title}</h3>
            <p className="info para small">{work.field}</p>
          </Link>
        ))}
      </div>
    </section>
  );
}
