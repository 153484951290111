import React from 'react';
import { Link } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import Arrow from '../../images/ico-arrow-down.svg';
import AboutBadge from '../../images/theme1/badge-about.svg';

const serializers = {
  types: {
    block: ({ children }) => <h2 className="split-lines">{children}</h2>,
  },
  marks: {
    strong: ({ children }) => <span>{children}</span>,
  },
};

export default function WhatIDo({ data }) {
  return (
    <section id="what-i-do">
      <div className="wrapper col-grid">
        <div className="sec-title">
          <div className="title">
            <span>What I do</span>
          </div>
        </div>

        <div className="about-content">
          <PortableText blocks={data._rawTitle} serializers={serializers} />

          <div className="all-services">
            {data.services.map((service, i) => (
              <div className="service accordion-reveal-1" key={i}>
                <div className="accordion heading">
                  <span className="s-no ">{`0${i + 1}/`}</span>
                  <h3 className="title">{service.title}</h3>
                  <div className="plus-icon">
                    <span />
                    <span />
                  </div>
                </div>
                <div className="accordion-content">
                  <p className="info para small">{service.para}</p>
                </div>
                <div className="stroke" />
              </div>
            ))}
          </div>

          <div className="about-blurb">
            <p className="para split-lines">{data.para}</p>
            <Link to="/about" className="down-arrow">
              <img src={Arrow} alt="arrow" className="arrow" />
              <img src={AboutBadge} alt="About me badge" className="badge" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
