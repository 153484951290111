import React from 'react';
import Mail from '../../images/theme1/ico-mail-outline.svg';
import Dribbble from '../../images/theme1/ico-dribbble-outline.svg';
import Instagram from '../../images/theme1/ico-instagram-outline.svg';
import Linkedin from '../../images/theme1/ico-linkedin-outline.svg';
import Arrow from '../../images/ico-arrow-down.svg';

export default function Hero1() {
  return (
    <>
      <div className="hero t1-hero">
        <div className="wrapper col-grid">
          <div className="empty" />
          <div className="hero-content">
            <h1 className="split-n-wrap">Rekhchand Sahu</h1>
            <h2 className="subhead split-n-wrap">
              UX/UI designer &amp; Creative developer specialised in{' '}
              <span>Websites</span> &amp; <span>Webapps</span>
            </h2>

            <div className="social-links">
              <a href="mailto:rekhchandsahu07@gmail.com" className="gmail">
                <img src={Mail} alt="Gmail" />
              </a>
              <a
                href="https://dribbble.com/r3khchand"
                target="_blank"
                className="dribbble"
                rel="noreferrer"
              >
                <img src={Dribbble} alt="Dribbble" />
              </a>
              <a
                href="https://www.instagram.com/rekhchand_design/"
                target="_blank"
                className="instagram"
                rel="noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
              <a
                href="https://www.linkedin.com/in/rekhchandsahu/"
                target="_blank"
                className="linkedin"
                rel="noreferrer"
              >
                <img src={Linkedin} alt="Linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="arrow-info">
        <div className="wrapper col-grid">
          <div className="down-arrow">
            <img src={Arrow} alt="arrow" className="arrow" />
          </div>
          <div className="info">
            <span>Porfolio &copy;{new Date().getFullYear()}</span>
          </div>
        </div>
      </div>
    </>
  );
}
