import React, { useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { swingingText } from '../../uitilities/caseHovers';

export default function SelectedWork() {
  const data = useStaticQuery(graphql`
    {
      allSanityCaseStudy(sort: { fields: order }) {
        nodes {
          title
          slug {
            current
          }
          field
          thumbnail {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const allCases = data.allSanityCaseStudy.nodes;

  useEffect(() => {
    swingingText();
  });

  return (
    <section id="selected-work-sec">
      <div className="wrapper col-grid">
        <div className="sec-title">
          <div className="title">
            <span>Selected work</span>
          </div>
        </div>

        <div className="all-cases">
          {allCases.map((item, i) => (
            <Link
              to={`/${item.slug.current}`}
              className="case-study element-reveal"
              key={i}
            >
              <div className="info">
                <h3>
                  <div className="case-title title-1">{item.title}</div>
                  <div className="case-title title-2">{item.title}</div>
                </h3>
                <span className="field">{item.field}</span>
              </div>
              <div className="thumbnail item-img-wrap">
                <div className="thumbnail-content item-img">
                  <GatsbyImage
                    image={item.thumbnail.asset.gatsbyImageData}
                    alt={item.title}
                  />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}
