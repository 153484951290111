import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function MoreWork({ allWork }) {
  return (
    <section className="more-work">
      <div className="more-work-contain">
        <div className="all-work change-direction">
          {allWork.map((work, i) => (
            <div className="work-contain" key={i}>
              <div className="work">
                {work.asset && (
                  <GatsbyImage
                    image={work.asset.gatsbyImageData}
                    alt={work.alt}
                  />
                )}
                {work.video && (
                  <video
                    src={work.video.asset.url}
                    poster={work.thumb.asset.url}
                    type="video/mp4"
                    muted
                    autoPlay
                    loop
                    playsInline
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                  />
                )}
              </div>
            </div>
          ))}
          {allWork.map((work, i) => (
            <div className="work-contain" key={i}>
              <div className="work">
                {work.asset && (
                  <GatsbyImage
                    image={work.asset.gatsbyImageData}
                    alt={work.alt}
                  />
                )}
                {work.video && (
                  <video
                    src={work.video.asset.url}
                    poster={work.thumb.asset.url}
                    type="video/mp4"
                    muted
                    autoPlay
                    loop
                    playsInline
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <p className="more-on element-reveal">
        more on:{' '}
        <a
          href="https://dribbble.com/r3khchand"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          Dribbble
        </a>
        ,{' '}
        <a href="https://www.instagram.com/rekhchand_design/" className="link">
          Instagram
        </a>
      </p>
    </section>
  );
}
