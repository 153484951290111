import React from 'react';
import Hero3 from './Hero3';
import SelectWorks from './SelectWorks';
import WhatIOffer from './WhatIOffer';
import MoreWork from './MoreWork';

import '../../css/theme3/theme-style.scss';

export default function HomeContent3({ data }) {
  const { whatIDo } = data.sanityHomepage;
  const { allWork } = data.sanityHomepage.moreWork;

  return (
    <>
      <Hero3 />
      <SelectWorks />
      <WhatIOffer data={whatIDo} />
      <MoreWork allWork={allWork} />
    </>
  );
}
